import {FC, useEffect, useState} from "react";
import * as React from "react";
import {
    Button, Checkbox,
    Flex, Heading, Input, InputGroup, Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter, ModalHeader,
    ModalOverlay, Select, Spacer, Text, Textarea, useDisclosure, useToast, VStack
} from "@chakra-ui/react";
import {ChevronRightIcon, DeleteIcon} from "@chakra-ui/icons";
import {FrequencyBlock, getFrequencyBlockDescription} from "../../enum/provider/calendar/FrequencyBlock";
import {useNavigate} from "react-router-dom";
import api from "../../services/api";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";


interface CalendarBlockDeleteModalProps {
    selectBlock:{
        id:string;
        created_at:string;
        deleted_at:string;
        ends_at:string;
        frequency:string;
        reason:string;
        starts_at:string;
        status:string;
    };

    loadBlocks: ()=>void
}

const CalendarBlockDeleteModal: FC<CalendarBlockDeleteModalProps> = ({selectBlock,loadBlocks}) => {

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const deleteBlock = (id: number) => {
        api.delete(`/provider/calendar/block/${id}`, { method: "delete" })
            .then((response) => {
                onClose();
                if (response?.status == 200) {
                    toast({
                        title: "Bloqueio cancelado com sucesso.",
                        position: "top-right",
                        status: "success",
                        duration: 8000,
                        isClosable: true,
                    });
                }
                loadBlocks()
            }).catch(reason => {
                toast({
                    title: "Não foi possivel cancelar o bloqueio.",
                    position: "top-right",
                    description: reason.response.data?.message,
                    status: "error",
                    duration: 9500,
                    isClosable: true,
                });
        });
    };

    return (

        <Flex
        >

            <Button onClick={onOpen} background={'#F50041'} color={'white'} mt={6} gap={3}><DeleteIcon/>Excluir bloqueio</Button>

            <Modal
                size="xs"
                blockScrollOnMount={false}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmar bloqueio</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={"4"}>
                            <h1>Você deseja remover esse bloqueio:</h1>

                            <VStack spacing={"1"}>
                                <h1>
                                    {selectBlock?.starts_at &&
                                        new Date(selectBlock.starts_at).toLocaleDateString(
                                            "pt-BR",
                                            {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }
                                        )}
                                </h1>
                                <h1>até</h1>
                                <h1>
                                    {selectBlock?.ends_at &&
                                        new Date(selectBlock.ends_at).toLocaleDateString("pt-BR", {
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })}
                                </h1>
                            </VStack>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Flex justify={"center"} w={"100%"}>
                            <Button
                                type={"button"}
                                onClick={() => deleteBlock(Number(selectBlock.id))}
                            >
                                Cancelar o bloqueio
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>

    );
};
export default CalendarBlockDeleteModal;
