import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Heading,
  Input,
  InputGroup,
  Textarea,
  Flex, useToast, Text,
} from "@chakra-ui/react";
import * as React from "react";
import {FC, useState} from "react";
import {toFormData} from "../util/form-data";
import api from "../services/api";

interface ReportProps {
  session_id: string
}

const Report: FC<ReportProps> = ({session_id}) => {

  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [theme, setTheme] = useState('');
  const [message, setMessage] = useState('');

  const handleReport = () => {
    const body = {theme, message, session_id}


    api.post('/provider/change-reports',  body)
        .then(() => {
          toast({
            title: "Relato salvo com sucesso.",
            position: "top-right",
            status: "success",
            duration: 16000,
            isClosable: true,
          });
          onClose()
        }).catch(reason => {
          toast({
            title: "Não foi possivel salvar o relato.",
            position: "top-right",
            status: "error",
            duration: 16000,
            isClosable: true,
          });
        })

  }

  return (
    <Box>
      <Button onClick={onOpen} w={'100%'}>
        <Text>Reportar Alterações</Text>
      </Button>
      <Modal isOpen={isOpen} size="xs" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontFamily="base">
            Reportar Alterações
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction='column' gap={8}>
              <div className="flex flex-col gap-1">
                <Heading variant="bodyTextLarge" color="low.pure">
                  Assunto:
                </Heading>
                <InputGroup>
                  <Input type="text" size='sm' placeholder="Ex: Telefone" onChange={(value)=>{setTheme(value.target.value)}}/>
                </InputGroup>
              </div>
              <div className="flex flex-col gap-1">
                <Heading variant="bodyTextLarge" color="low.pure">
                  Conta mais!
                </Heading>
                <Textarea
                  onChange={(value)=>{setMessage(value.target.value)}}
                  size="xs"
                  placeholder="Nos deixe saber o que precisa ser alterado no ensaio"
                />
              </div>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="tertiary" onClick={onClose}>
              Sair
            </Button>
            <Button
              variant="primary"
              mr={3}
              onClick={handleReport}
              rightIcon={<ChevronRightIcon w={6} h={6} />}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Report;
