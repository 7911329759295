import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Spacer } from "@chakra-ui/react";
import { FC } from "react";
import { Link } from "react-router-dom";

interface BackBarProps {}

const BackBar: FC<BackBarProps> = () => {
  const HomeIcon = (props: any) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
    </svg>
  );

  return (
    <Flex className="bg-white w-full flex items-center">
      <IconButton
        bg="white"
        aria-label="Voltar"
        icon={<ArrowBackIcon w={6} h={6} />}
        onClick={() => {
          window.history.back();
        }}
      />
      <Spacer />
      <Link to={"/"}>
        <Box className="mx-3">
          <HomeIcon />
        </Box>
      </Link>
    </Flex>
  );
};

export default BackBar;
