import {redirect, useParams} from "react-router-dom";
import {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import {useAuth} from "../contexts/auth/AuthContext";
import api from "../services/api";
import Cookie from "js-cookie";

interface ImpersonationProps {}

const DeliverableView: React.FC<ImpersonationProps> = () => {
    const hash = useParams().hash;
    const auth = useAuth();
    const navigate = useNavigate();

    api.get(`/provider/impersonation-provider/${hash}`)
        .then(value => {
                if (value.status == 200){
                    Cookie.set('authentication', value.data.plainTextToken,{
                        expires: 3,
                        secure: true,
                        path: '/'
                    })

                    api.interceptors.request.use(async config => {
                        config.headers.Authorization = `Bearer ${Cookie.get('authentication')}`;
                        return config;
                    });

                    api.get(`/provider/auth/user`)
                        .then(response => {
                            if (response.status == 200) {
                                auth.setUser(response.data)
                            } else {
                                auth.setUser(null)
                                Cookie.remove('authentication')
                            }
                        })
                        .catch(() => {
                            auth.setUser(null)
                            Cookie.remove('authentication')
                        })
                }
            }
        )



    return (
        <>
        </>
    );
};

export default DeliverableView;
