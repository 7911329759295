import * as React from 'react';
import  { FC } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Flex,
    Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, useDisclosure, useToast
} from "@chakra-ui/react";
import {
    addMonths,
    endOfMonth,
    isThisMonth,
    startOfMonth,
    subMonths
} from "date-fns";
import { useEffect, useState } from "react";
import BackBar from "../../components/backBar";
import { useInvoices } from "../../hooks/data/invoice";
import { formatDate } from "../../util/date";
import { toFormData } from "../../util/form-data";

import { FileWithPath, useDropzone } from "react-dropzone";
import api from "../../services/api";



interface LoginProps {}

const getParams = (date: Date) => ({
    filter: {
        issued_at: [formatDate(startOfMonth(date)), formatDate(endOfMonth(date))],
    },
});

let skipped = false;
const Invoices: FC<LoginProps> = () => {

    const toast = useToast();
    const [date, setDate] = useState(new Date());
    const [params, setParams] = useState(getParams(date));
    const invoices = useInvoices(params);
    const [viewUpload, setViewUpload] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [event, setEvent] = useState<React.ChangeEvent<HTMLInputElement> | null>();
    const [upload, setUpload] = useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onOpen();
        setEvent(event);
    };
    console.log('invoices')
    console.log(invoices)
    const handleSave = () => {
        if (event?.target.files) {
            const files = Array.from(event.target.files);
            if (files.length) {
                const requests = files.map(file => {
                    setUpload(true)
                    const body = toFormData({ file, issued_at: formatDate(date) })
                    api.post(`/provider/invoices`,  body).then(({status}) => {
                        if (status == 200){
                            toast({
                                title: "Arquivo salvo com sucesso.",
                                position: "top-right",
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                            });
                            window.location.reload();
                        } else {
                            toast({
                                title: "Não foi possivel salvar o arquivo.",
                                position: "top-right",
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                            });
                            onClose();
                        }
                    });
                });
                Promise.allSettled(requests).finally(() => {
                    setParams({ ...params });
                });
            }
        }
    };
    useEffect(() => {
        if (skipped) {
            setParams(getParams(date));
        } else {
            skipped = true;
        }
    }, [date]);

    if (new Date().getDay() < 25 && date.getMonth() == new Date().getMonth()){
        setDate(date => subMonths(date , 1))
        setParams(getParams(date))
    }

    const monthsAdd = () => {
        if (new Date().getMonth() - 2 <= new Date(date).getMonth()) {
            setViewUpload(true);
        }

        setDate((date) => addMonths(date, 1));
    };

    const monthsSub = () => {
        if (new Date().getMonth() > new Date(date).getMonth()) {
            setViewUpload(false);
        }
        setDate((date) => subMonths(date, 1));
    };

    /* upload */
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const fileList = (files: FileWithPath[]): React.ReactNode =>
        files.map((file) => (
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
        ));

    return (
        <Box
            px={5}
            pt={4}
            pb="10"
            className="absolute top-0 inset-x-0 bg-white w-full"
        >
            <BackBar />

            <Box className="grid gap-8 max-w-lg bg-white m-auto mt-6">
                <Flex direction="column" gap="2">
                    <Heading size="medium">Notas fiscais</Heading>
                    <Heading variant="bodyCapSmall" className="w-4/5">
                        Envie suas notas e veja as que você já nos mandou!!
                    </Heading>
                </Flex>
                <Box className="flex items-center justify-between">
                    <button type="button" onClick={monthsSub}>
                        <ChevronLeftIcon w={6} h={6} />
                    </button>
                    <span>{formatDate(date, "MMMM 'de' yyyy")}</span>
                    <button
                        type="button"
                        disabled={isThisMonth(date)}
                        onClick={monthsAdd}
                    >
                        <ChevronRightIcon w={6} h={6} />
                    </button>
                </Box>
                {upload &&
                    <Flex w={'100%'} justifyContent={'center'} mt={5}>
                        <Spinner speed='0.65s' size={'xl'}/>
                    </Flex>
                }
                {viewUpload && !upload &&
                    <div className="flex justify-center items-center w-full">
                        <label
                            htmlFor="dropzone-file"
                            className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        >
                            <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                <svg
                                    className="mb-3 w-10 h-10 text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    ></path>
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">Selecione a NFe</span>
                                </p>
                            </div>
                            <input onChange={handleChange} id="dropzone-file" accept={'.pdf'} type="file" className="hidden"/>
                        </label>
                    </div>}
                {invoices?.map(invoice => (
                    <div key={invoice.id} className="truncate">
                        <a href={invoice.file.url} rel="noopener noreferrer" target="_blank">
                            {invoice.file.name} - {formatDate(invoice.issued_at, 'dd/MM/yyyy') || '-'}
                        </a>
                    </div>
                ))}

                <Button variant="secondary">Sair</Button>
            </Box>

            <Modal isOpen={isOpen} size="xs" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize='lg' fontFamily='base'>Salvar nota fiscal</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody fontFamily='base'>
                        Salvar nota fiscal referente ao mês de {formatDate(date, "MMMM 'de' yyyy")}
                        {upload &&
                            <Flex w={'100%'} justifyContent={'center'} mt={25}>
                                <Spinner speed='0.65s' size={'xl'}/>
                            </Flex>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="tertiary" onClick={onClose}>Sair</Button>
                        <Button variant='primary' bg='secondary.pure' mr={3} onClick={handleSave} rightIcon={<ChevronRightIcon w={6} h={6} />}>
                            Salvar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Invoices;
