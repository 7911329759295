import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import {ChakraProvider} from "@chakra-ui/react";
import {ptheme} from "./styles/theme";
import {Fonts} from "./styles/fonts";
import {AuthContextProvider} from "./contexts/auth/AuthContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthContextProvider>
      <ChakraProvider theme={ptheme}>
          <Fonts />
          <App />
      </ChakraProvider>
  </AuthContextProvider>
);

