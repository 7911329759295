import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Flex, Grid,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList
} from "@chakra-ui/react";
import { FC, useState } from "react";
import {Link, NavLink} from "react-router-dom";
import NewLogoText from "../styles/icons/NewLogoText";
import Cookie from "js-cookie";
import {useAuth} from "../contexts/auth/AuthContext";
import NotificationIcon from "../styles/icons/NotificationIcon";
import TodayIcon from "../styles/icons/TodayIcon";
import NotesIcon from "../styles/icons/NotesIcon";
import TomorrowIcon from "../styles/icons/TomorrowIcon";
import {bg} from "date-fns/locale";

interface NavBarProps {}

const NavBar: FC<NavBarProps> = () => {

    const auth = useAuth()

    const logOut = () => {
        auth.setUser(null)
        Cookie.remove('authentication')
    }

    const [display, setDisplay] = useState("none");

    const changeDisplay = () => {
        if (display === "none") {
            setDisplay("flex");
        } else if (display === "flex") {
            setDisplay("none");
        }
    };

    return (
        <Box m={'4'}>
            <Box>
                <Flex justifyContent={'space-between'}>
                    <Link to={'/'}>
                        <NewLogoText />
                    </Link>
                    {/*<Flex background={'gray.200'} borderRadius={'3xl'} w={'7'} h={'7'} justifyContent={'center'} alignItems={'center'}>*/}
                        {/*<NotificationIcon />*/}
                    {/*</Flex>*/}
                </Flex>
            </Box>

        </Box>
    );
};

export default NavBar;
