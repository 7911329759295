import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/authentication/Login";
import "./index.css";
import NavBar from "./components/NavBar";
import Region from "./pages/Region/Region";
import Invoices from "./pages/invoices/Invoices";
import Calendar from "./pages/calendar/Calendar";
import ClientList from "./pages/Client/ClientList";
import DeliverableView from "./pages/Deliverables/DeliverableView";
import {useAuth} from "./contexts/auth/AuthContext";
import Identification from "./pages/Identification/Identification";
import SessionListToday from "./pages/sessions/SessionListToday";
import SessionListNext from "./pages/sessions/SessionListNext";
import SessionListReminder from "./pages/sessions/SessionListReminder";
import Impersonation from "./components/Impersonation";
import CalendarView from "./pages/calendar/CalendarView";

function App() {

    const auth = useAuth();

    if (auth.user === undefined) {
        return null;
    }
console.log(auth.user)
  return (
      <>
          <BrowserRouter>
              {auth.user?.id && <NavBar/>}
              <Routes>
                  {!auth.user?.id ? (
                      <>
                          <Route path="/impersonation-provider/:hash" element={<Impersonation />} />
                          <Route path="/login" element={<Login />} />
                          <Route path={"*"} element={<Navigate replace to="/login" />} />
                      </>

                  ) : (
                      <>
                          <Route path={"/"} element={<SessionListToday />} />
                          <Route path={"/ensaios/lembretes"} element={<SessionListReminder />} />
                          <Route path={"/ensaios/amanha"} element={<SessionListNext />} />
                          <Route path={"/ensaios/:id"} element={<DeliverableView />} />
                          <Route path={"/parceiros"} element={<ClientList />} />
                          <Route path={"/agenda"} element={<Calendar />} />
                          <Route path={"/agenda-view"} element={<CalendarView />} />
                          {/*<Route path={"/regiao"} element={<Region />} />*/}
                          {/*<Route path={"/faturamento"} element={<Financial />} />*/}
                          {/*<Route path={"/fiscal"} element={<Invoices />} />*/}
                          <Route path={"/fiscal"} element={<Invoices />} />
                          <Route path="/identification" element={<Identification />} />
                          <Route path={"*"} element={<Navigate replace to="/" />} />
                      </>
                  )}
              </Routes>

          </BrowserRouter>


      </>
  );
}

export default App;
