import { Icon } from "@chakra-ui/icons";

interface PhotoCameraIconIconProps {
    fill?: string
}

const PhotoCameraIcon: React.FC<PhotoCameraIconIconProps> = ({fill= '#4D5562'}) => {
  return (
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 14.3846C11.2078 14.3846 12.2318 13.9645 13.0718 13.1245C13.9119 12.2844 14.332 11.2604 14.332 10.0526C14.332 8.84478 13.9119 7.82084 13.0718 6.98076C12.2318 6.14069 11.2078 5.72065 10 5.72065C8.79217 5.72065 7.76822 6.14069 6.92815 6.98076C6.08808 7.82084 5.66804 8.84478 5.66804 10.0526C5.66804 11.2604 6.08808 12.2844 6.92815 13.1245C7.76822 13.9645 8.79217 14.3846 10 14.3846ZM1.90285 17.9473C1.37113 17.9473 0.921055 17.7631 0.552633 17.3947C0.184211 17.0263 0 16.5762 0 16.0445V4.06073C0 3.52901 0.184211 3.07894 0.552633 2.71051C0.921055 2.34209 1.37113 2.15788 1.90285 2.15788H5.11741L6.49394 0.665983C6.67343 0.464913 6.88396 0.312412 7.12552 0.208482C7.36708 0.104569 7.62349 0.0526123 7.89473 0.0526123H12.1053C12.3765 0.0526123 12.6329 0.104569 12.8745 0.208482C13.116 0.312412 13.3266 0.464913 13.5061 0.665983L14.8826 2.15788H18.0972C18.6289 2.15788 19.0789 2.34209 19.4474 2.71051C19.8158 3.07894 20 3.52901 20 4.06073V16.0445C20 16.5762 19.8158 17.0263 19.4474 17.3947C19.0789 17.7631 18.6289 17.9473 18.0972 17.9473H1.90285ZM18.0972 16.3684C18.1916 16.3684 18.2692 16.338 18.33 16.2773C18.3907 16.2166 18.4211 16.139 18.4211 16.0445V4.06073C18.4211 3.96626 18.3907 3.88865 18.33 3.82791C18.2692 3.76717 18.1916 3.73681 18.0972 3.73681H1.90285C1.80837 3.73681 1.73077 3.76717 1.67003 3.82791C1.60929 3.88865 1.57893 3.96626 1.57893 4.06073V16.0445C1.57893 16.139 1.60929 16.2166 1.67003 16.2773C1.73077 16.338 1.80837 16.3684 1.90285 16.3684H18.0972Z" fill={fill}/>
      </svg>


  );
};

export default PhotoCameraIcon;
