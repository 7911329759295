import { useEffect, useState } from 'react'
import api from "../services/api";

export function useTagCategory(key: number | string) {
  const [tagCategory, setTagCategory] = useState<Model.TagCategory>()
  useEffect(() => {
    setTagCategory(undefined)
    api.get(`/provider/tag-categories/${key}`)
      .then(response => setTagCategory(response.data))
  }, [key])
  return tagCategory
}
