import {
    Box,
} from "@chakra-ui/react";
import * as React from 'react';
import {FC, useEffect, useState} from "react";
import ClientCard from "../../components/ClientCard";
import api from "../../services/api";


interface LoginProps {}


const ClientList: FC<LoginProps> = () => {

    const [clients, setClients] = useState<{id:string; briefing_photo:string;
        briefing_video: string; created_at:string; deleted_at: string|null; name: string;
        items:{price:string; title:string; id:string}[]; regions:{name:string; id:string}[];
    }[]>([]);

    useEffect(() => {
        api.get(`/provider/clients`)
            .then(response => {
                if (response.status==200) {
                    setClients(response.data)
                }
            })
    }, [])
    return (
        <>
            <Box
                display={'flex'}
                justifyContent={'center'}
                pt={["40px"]} mb={'80px'}
            >
                <div className="container">
                    <Box className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-10" >
                        {clients.map(value =>
                            <ClientCard client={value}/>
                        )}
                    </Box>

                </div>
            </Box>

        </>
    );
};

export default ClientList;
