export const IconButtonStyles = {
    baseStyles: {},
  
    sizes: {
      xs: {
        width: "24px",
        height: "24px",
        py: "10px",
        px: "8px",
      },
  
      md: {
        fontSize: "xs",
        lineHeight: "lg",
        py: "10px",
        px: "16px",
      },
  
      sm: {
        fontSize: "xs",
        lineHeight: "lg",
        py: "10px",
        px: "12px",
      },
  
      lg: {
        fontSize: "md",
        lineHeight: "lg",
        py: "10px",
        px: "24px",
      },
    },
  
    variants: {
      primary: () => ({
        bg: "primary.pure",
        color: "white",
        _hover: {
          bg: "primary.medium",
        },
        _focus: {
          bg: "primary.dark",
          ring: "none",
        },
        _disabled: {
          opacity: "1",
          bg: "primary.light",
        },
      }),
  
      secondary: () => ({
        bg: "transparent",
        border: "1px solid",
        borderColor: "low.medium",
        color: "low.medium",
        fontWeight: "500",
        lineHeight: "lg",
        borderRadius: "md",
        _hover: {
          color: "low.medium",
          bg: "high.light",
        },
        _focus: {
          color: "low.medium",
          bg: "high.medium",
          ring: "none",
        },
        _disabled: {
          border: "high.pure",
        },
      }),
  
      tertiary: () => ({
        bg: "transparent",
        color: "low.medium",
        fontWeight: "500",
        lineHeight: "lg",
        _hover: {
          color: "low.medium",
          bg: "high.light",
        },
        _focus: {
          color: "low.medium",
          bg: "high.medium",
          ring: "none",
        },
        _disabled: {
          border: "none",
        },
      }),
    },
  
    defaultProps: {},
  };