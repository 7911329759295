import { useEffect, useState } from 'react'
import { toFormData } from '../../util/form-data'
import api from "../../services/api";

export function useInvoices(params?: Http.Params, paging?: Http.Pagination, setMeta?: (_: Http.ResponseMeta) => void) {
  const [invoices, setInvoices] = useState<Model.Invoice[]>()
  useEffect(() => {
    setInvoices(undefined)
    const body = toFormData(params)
    const search = new URLSearchParams()
    if (paging) {
      search.set('page', `${paging.page}`)
      search.set('length', `${paging.length}`)
    }
    api.post(`/provider/invoices/search?${search.toString()}`, body)
      .then(({ data }) => {
        setInvoices(data)
        setMeta?.(data.meta)
      })
  }, [paging, params, setMeta])
  return invoices
}
