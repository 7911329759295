import { Icon } from "@chakra-ui/icons";

interface NewLogoTextProps {}

const NewLogoText: React.FC<NewLogoTextProps> = () => {
  return (
    <Icon
      width="1555"
      height="10"
      viewBox="0 0 1555 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_356_2348)">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
          d="M0 -0.0020752H279.901C433.846 -0.0020752 559.803 125.999 559.803 280V280.002C559.803 434.004 433.846 560.005 279.901 560.005C125.955 560.005 0 434.004 0 280.002V-0.0020752H0Z"
          fill="#0CF67E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
          d="M931.13 145.456H966.779V177.876H931.13V145.456ZM931.13 200.57H966.779V346.462H931.13V200.57ZM1206.79 281.091C1210.71 306.146 1231.7 317.231 1255.54 317.231C1271.06 317.231 1281.72 312.28 1293.64 302.614L1310.85 325.202C1295.59 338.509 1272.01 346.462 1251.87 346.462C1208.54 346.462 1172.57 319.983 1172.57 274.978C1172.57 231.286 1204.33 200.57 1248.49 200.57C1266.5 200.57 1283.94 207.199 1296.61 219.191C1315.73 237.292 1317.11 256.731 1316.49 281.089L1206.79 281.091V281.091ZM1207.56 258.768L1282.92 258.77C1282.23 239.04 1262.83 228.797 1244.79 229.474C1226.11 230.173 1211.65 240.379 1207.56 258.768ZM1486.22 317.948H1555V346.462H1446.43V322.102L1514.02 229.084H1446.43V200.57H1553.14V224.93L1486.22 317.948ZM1425.36 200.57H1347.59V346.462H1383.23V232.99H1425.36V200.57V200.57ZM1002.43 200.57V414.544H1038.31V325.709C1049.45 338.626 1056.46 344.72 1074.81 345.761C1088.56 346.539 1103.15 342.844 1114.19 337.006C1137.41 324.723 1149.55 303.238 1151.5 277.555C1152.84 259.884 1148.58 241.677 1137.41 227.646C1120.4 206.28 1097.02 201.641 1071.22 201.108C1045.22 200.57 1003.01 200.57 1002.43 200.57ZM1077.31 232.263H1035.91V273.686C1035.91 296.56 1054.45 315.104 1077.31 315.104C1100.19 315.104 1118.72 296.56 1118.72 273.686C1118.72 250.807 1100.19 232.263 1077.31 232.263ZM746.4 200.57V414.544H782.274V325.709C793.419 338.626 800.43 344.72 818.771 345.761C832.526 346.539 847.114 342.844 858.153 337.006C881.381 324.723 893.52 303.238 895.466 277.555C896.804 259.884 892.55 241.677 881.378 227.646C864.368 206.28 840.982 201.641 815.183 201.108C789.181 200.57 746.971 200.57 746.4 200.57V200.57ZM821.277 232.263H779.874V273.686C779.874 296.56 798.411 315.104 821.277 315.104C844.152 315.104 862.689 296.56 862.689 273.686C862.689 250.807 844.152 232.263 821.277 232.263Z"
          fill="#2D2D2D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
          d="M279.901 146.95H186.599V240.284C186.599 291.831 228.37 333.617 279.901 333.617C331.429 333.617 373.2 291.831 373.2 240.284C373.2 188.737 331.429 146.95 279.901 146.95Z"
          fill="#2D2D2D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
          d="M233.25 319.716H186.599V366.384C186.599 392.158 207.486 413.05 233.25 413.05C259.014 413.05 279.901 392.158 279.901 366.384C279.901 340.611 259.014 319.716 233.25 319.716Z"
          fill="#2D2D2D"
        />
      </g>
      <defs>
        <clipPath id="clip0_356_2348">
          <rect width="1555" height="560" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default NewLogoText;
