export const TabStyles = {
    baseStyles: {},
    sizes: {},
    variants: {
        primary: () => ({
            fontSize:"14px",
            border:"1px",
            borderColor:"low.medium",
            borderRadius:"99999px",
            px:"16px",
            _selected:{
              color: "white",
              bg: "primary.pure",
              borderRadius: "99999px",
              border: "0px",
              ring: 0,
            },
            _hover:{ ring: "0" }
        })
    },
    defaultProps: {},
}