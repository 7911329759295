export enum Type {
  PROVIDER_CALLED = 0,
  PROVIDER_NOTIFY_IN_ROUTE = 1,
  PROVIDER_MESSAGE_WHATSAPP = 2,
  CONFIRMED_SESSION = 3,
  ADMIN_MESSAGE_CONFIMATION = 4,
  PROVIDER_MESSAGE_CONFIMATION = 5,
  MESSAGE_ADMIN_SESSION = 6,
  APPROVED_PHOTO = 7,
  DISAPPROVED_PHOTO = 8,
}
