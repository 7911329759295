import { Icon } from "@chakra-ui/icons";

interface TodayIconProps {
    fill?: string
}

const TodayIcon: React.FC<TodayIconProps> = ({fill= '#4D5562'}) => {
  return (
      <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.83331 12.3815C4.29806 12.3815 3.84374 12.1948 3.47033 11.8214C3.09694 11.448 2.91025 10.9937 2.91025 10.4584C2.91025 9.92316 3.09694 9.46884 3.47033 9.09545C3.84374 8.72205 4.29806 8.53535 4.83331 8.53535C5.36856 8.53535 5.82289 8.72205 6.19629 9.09545C6.56968 9.46884 6.75638 9.92316 6.75638 10.4584C6.75638 10.9937 6.56968 11.448 6.19629 11.8214C5.82289 12.1948 5.36856 12.3815 4.83331 12.3815ZM1.75642 16.9167C1.33547 16.9167 0.979167 16.7709 0.6875 16.4792C0.395833 16.1876 0.25 15.8313 0.25 15.4103V4.25653C0.25 3.83559 0.395833 3.47928 0.6875 3.18762C0.979167 2.89595 1.33547 2.75012 1.75642 2.75012H2.91027V0.987305H4.19229V2.75012H10.5064V0.987305H11.7564V2.75012H12.9102C13.3312 2.75012 13.6875 2.89595 13.9791 3.18762C14.2708 3.47928 14.4166 3.83559 14.4166 4.25653V15.4103C14.4166 15.8313 14.2708 16.1876 13.9791 16.4792C13.6875 16.7709 13.3312 16.9167 12.9102 16.9167H1.75642ZM1.75642 15.6668H12.9102C12.9743 15.6668 13.0331 15.6401 13.0865 15.5866C13.1399 15.5332 13.1666 15.4744 13.1666 15.4103V7.58987H1.49998V15.4103C1.49998 15.4744 1.52669 15.5332 1.5801 15.5866C1.63353 15.6401 1.69231 15.6668 1.75642 15.6668ZM1.49998 6.33989H13.1666V4.25653C13.1666 4.19242 13.1399 4.13365 13.0865 4.08022C13.0331 4.02681 12.9743 4.0001 12.9102 4.0001H1.75642C1.69231 4.0001 1.63353 4.02681 1.5801 4.08022C1.52669 4.13365 1.49998 4.19242 1.49998 4.25653V6.33989Z" fill={fill}/>
      </svg>

  );
};

export default TodayIcon;
