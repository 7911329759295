export const ButtonStyles = { 
    baseStyles: {},
    sizes: {
        xs: {
          fontSize: "xxs",
          lineHeight: "md",
          letterSpacing: "0.02em",
          py: "10px",
          px: "8px",
        },
    
        md: {
          fontSize: "xs",
          lineHeight: "lg",
          py: "10px",
          px: "16px",
        },
    
        sm: {
          fontSize: "xs",
          lineHeight: "lg",
          py: "10px",
          px: "12px",
        },
    
        lg: {
          fontSize: "md",
          lineHeight: "lg",
          py: "10px",
          px: "24px",
        },
      },
    variants: {
        primary: () => ({
          fontFamily: "base",
          bg: "primary.pure",
          color: "#2D2D2D",
          fontWeight: "500",
          lineHeight: "lg",
          borderRadius: "md",
          width: "100%",
          _hover: {
            bg: "primary.medium",
          },
          _focus: {
            bg: "primary.dark",
            ring: "none",
          },
          _disabled: {
            opacity: "1",
            bg: "primary.light",
          },
        }),

        primaryOnColor: () => ({
          fontFamily: "base",
          bg: "secondary.pure",
          color: "white",
          fontWeight: "500",
          lineHeight: "lg",
          borderRadius: "md",
          width: "100%",
          _hover: {
            bg: "secondary.medium",
          },
          _focus: {
            bg: "secondary.dark",
            ring: "none",
          },
          _disabled: {
            opacity: "1",
            bg: "secondary.light",
          },
        }),
    
        secondary: () => ({
          fontFamily: "base",
          bg: "transparent",
          border: "1px solid",
          borderColor: "low.pure",
          color: "low.pure",
          fontWeight: "500",
          lineHeight: "lg",
          borderRadius: "md",
          width: "100%",
          _hover: {
            color: "low.medium",
            bg: "high.light",
          },
          _focus: {
            color: "low.medium",
            bg: "high.medium",
            ring: "none",
          },
          _disabled: {
            border: "high.pure",
          },
        }),
        
        secondaryOnColor: () => ({
          fontFamily: "base",
          bg: "transparent",
          border: "1px solid",
          borderColor: "high.pure",
          color: "high.pure",
          fontWeight: "500",
          lineHeight: "lg",
          borderRadius: "md",
          width: "100%",
          _hover: {
            color: "high.pure",
            bg: "secondary.medium",
          },
          _focus: {
            color: "high.pure",
            bg: "secondary.medium",
            ring: "none",
          },
          _disabled: {
            border: "high.pure",
          },
        }),
    
        tertiary: () => ({
          fontFamily: "base",
          bg: "transparent",
          color: "low.pure",
          fontWeight: "500",
          lineHeight: "lg",
          width: "100%",
          _hover: {
            color: "low.medium",
            bg: "high.light",
          },
          _focus: {
            color: "low.medium",
            bg: "high.medium",
            ring: "none",
          },
          _disabled: {
            border: "none",
          },
        }),

        tertiaryOnColor: () => ({
          fontFamily: "base",
          bg: "transparent",
          color: "high.pure",
          fontWeight: "500",
          lineHeight: "lg",
          width: "100%",
          _hover: {
            color: "high.pure",
            bg: "secondary.medium",
          },
          _focus: {
            color: "high.pure",
            bg: "secondary.medium",
            ring: "none",
          },
          _disabled: {
            border: "none",
          },
        }),
    
        error: () => ({
          fontFamily: "base",
          bg: "error.pure",
          color: "white",
          fontWeight: "500",
          lineHeight: "lg",
          borderRadius: "md",
          _hover: {
            bg: "error.medium",
          },
          _focus: {
            bg: "error.dark",
            ring: "none",
          },
          _disabled: {
            opacity: "1",
            bg: "error.light",
          },
        }),
      },
    defaultProps: {},
}