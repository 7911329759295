export const TextStyles = {
    baseStyles: {},
  
    sizes: {
      large: {
        
      },
  
      medium: {
        
      },
  
      small: {
        
      },
  
      cap_medium: {

      },
  
      cap_small: {
        
      },
    },
  
    variants: {},
  
    defaultProps: {},
  };