export const HeadingStyles = {
    baseStyles: {},
  
    sizes: {
      display: {
        fontFamily: "base",
        fontWeight: "700",
        fontSize: "giant",
        lineHeight: "sm",
        color: "low.dark",
      },
      huge: {
        fontFamily: "base",
        fontWeight: "700",
        fontSize: "display",
        lineHeight: "sm",
        color: "low.dark",
      },
      xlarge: {
        fontFamily: "base",
        fontWeight: "700",
        fontSize: "xxxl",
        lineHeight: "sm",
        color: "low.dark",
      },
      large: {
        fontFamily: "base",
        fontWeight: "700",
        fontSize: "xxl",
        lineHeight: "sm",
        color: "low.dark",
      },
      medium: {
        fontFamily: "base",
        fontWeight: "600",
        fontSize: "xl",
        lineHeight: "md",
        color: "low.dark",
      },
      small: {
        fontFamily: "base",
        fontWeight: "600",
        fontSize: "lg",
        lineHeight: "md",
        color: "low.medium",
      },
      xsmall: {
        fontFamily: "base",
        fontWeight: "600",
        fontSize: "sm",
        lineHeight: "md",
        color: "low.medium",
      },
    },
  
    variants: {
      bodyTextLarge: () => ({
        fontFamily: "base",
        fontWeight: "400",
        fontSize: "md",
        lineHeight: "lg",
        color: "low.medium",
      }),
      bodyTextMedium: () => ({
        fontFamily: "base",
        fontWeight: "400",
        fontSize: "sm",
        lineHeight: "lg",
        color: "low.medium",
      }),
      bodyTextSmall: () => ({
        fontFamily: "base",
        fontWeight: "400",
        fontSize: "xs",
        lineHeight: "lg",
        color: "low.medium",
      }),
      bodyCapMedium: () => ({
        fontFamily: "base",
        fontWeight: "500",
        fontSize: "xxs",
        letterSpacing: "0.02em",
        lineHeight: "md",
        color: "low.medium",
      }),
      bodyCapSmall: () => ({
        fontFamily: "base",
        fontWeight: "500",
        fontSize: "xxxs",
        letterSpacing: "0.04em",
        lineHeight: "md",
        color: "low.medium",
      }),
    },
  
    defaultProps: {},
  };