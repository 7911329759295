import {FC, useEffect, useState} from "react";
import * as React from "react";
import api from "../../services/api";
import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex} from "@chakra-ui/react";
import SessionCard from "../../components/sessionCard";
import CalendarBlockCard from "./CalendarBlockCard";
import CalendarBlockCreateModal from "./CalerdarBlockCreateModal";
import Footer from "../../components/Footer";

const Calendar: FC<{}> = () => {

    const [blocks, setBlocks] = useState<
        {
            id:string;
            created_at:string;
            deleted_at:string;
            ends_at:string;
            frequency:string;
            reason:string;
            starts_at:string;
            status:string;

        }[]>([]);

    const loadBlocks = () => {
        api.post('provider/block').then(value =>
            setBlocks(value.data.data)
        )
    }
    useEffect(() => {
        loadBlocks()
    },[])


    return (

        <Flex
            display={'flex'}
            justifyContent={'center'}
            mb={'80px'}
            mt={'30px'}
            p={'4'}
        >

            <div className="container">
                <Flex justifyContent={'space-between'}>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <BreadcrumbLink fontSize={'2xl'} href='#'>Agenda</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <BreadcrumbLink href='#'>Bloqueios agendados</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>

                    <CalendarBlockCreateModal loadBlocks={loadBlocks} />
                </Flex>


                <Box className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-6" mt={8}>
                    {blocks.map(value =>
                        <CalendarBlockCard block={value} loadBlocks={loadBlocks} />
                    )}
                </Box>
            </div>
        <Footer/>
        </Flex>

    );
};
export default Calendar;
