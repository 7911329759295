import { Global } from "@emotion/react";

export const Fonts = () => (
  <Global
    styles={` 
        @font-face {
            font-family: 'Outfit';
            src: url('url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;800;900&display=swap');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
          }
        `}
  />
);
