import {FC, useEffect, useState} from "react";
import * as React from "react";
import {
    Button, Checkbox,
    Flex, Heading, Input, InputGroup, Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter, ModalHeader,
    ModalOverlay, Select, Spacer, Spinner, Text, Textarea, useDisclosure, useToast
} from "@chakra-ui/react";
import {ChevronRightIcon} from "@chakra-ui/icons";
import {FrequencyBlock, getFrequencyBlockDescription} from "../../enum/provider/calendar/FrequencyBlock";
import {useNavigate} from "react-router-dom";
import api from "../../services/api";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function formatDate(date: any) {
    function adicionaZero(numero: any) {
        if (numero <= 9) return "0" + numero;
        else return numero;
    }
    let data = new Date(date);
    let dataFormatada =
        data.getFullYear() +
        "-" +
        adicionaZero(data.getMonth() + 1) +
        "-" +
        adicionaZero(data.getDate());
    return dataFormatada;
}
function formatDateText(date: any) {
    function adicionaZero(numero: any) {
        if (numero <= 9) return "0" + numero;
        else return numero;
    }
    let data = new Date(date);
    let dataFormatada =
        adicionaZero(data.getDate()) +
        "/" +
        adicionaZero(data.getMonth() + 1) +
        "/" +
        data.getFullYear()
    return dataFormatada;
}

interface CalendarBlockCreateModalProps {
    loadBlocks: ()=>void
}
const CalendarBlockCreateModal: FC<CalendarBlockCreateModalProps> = ({loadBlocks}) => {

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenConfirm , onOpen: onOpenConfimr, onClose: onCloseConfirm } = useDisclosure();
    const history = useNavigate();

    const [isChecked, settIsChecked] = useState(false);
    const [text, setText] = useState<string>("");
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [timeStart, setTimeStart] = useState<any>("08:00");
    const [timeEnd, setTimeEnd] = useState<any>("18:00");
    const [repeat, setRepeat] = useState("0");
    const [errors, setErrors] = useState<any>();
    const [saving, setSaving] = useState<boolean>(false);

    const blockFrequency = [
        FrequencyBlock.DOES_NOT_REPEAT,
        FrequencyBlock.WEEKLY,
    ];

    var dias = ["domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]

    useEffect(() => {
        if (isChecked) {
            setTimeStart("00:00");
            setTimeEnd("23:59");
        }
    }, [isChecked]);

    const handleConfirmModal = (event: any) => {
        event.preventDefault();
        onClose();
        onOpenConfimr();

    };
    const handleConfirmModel = (event: any) => {
        setSaving(true)
        event.preventDefault();

        const body = {
            dateStart: formatDate(dateStart),
            dateEnd: formatDate(dateEnd),
            timeStart,
            timeEnd,
            reason: text,
            frequency: repeat,
        };

        api.post("/provider/calendar/block",  body)
            .then((response) => {
                if (response?.status == 201) {
                    setText("");
                    if (!isChecked) {
                        setTimeStart("");
                        setTimeEnd("");
                    }

                    setRepeat("0");
                    setErrors({});

                    toast({
                        title: "sucesso.",
                        position: "top-right",
                        description: response.data.message,
                        status: "success",
                        duration: 8000,
                        isClosable: true,
                    });
                    loadBlocks()
                    history("/agenda");
                } else {
                    if (response.status != 201) {

                        toast({
                            title: "Não foi possivel criar o bloqueio.",
                            position: "top-right",
                            description: response.data.message,
                            status: "error",
                            duration: 9500,
                            isClosable: true,
                        });

                        if (response.data.return) {
                            response.data.return?.map((res: { start: any; end: any }) => {
                                let start = new Date(res?.start);
                                let end = new Date(res?.end);

                                let message =
                                    start.toLocaleDateString("pt-BR", {
                                        day: "2-digit",
                                        month: "long",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    }) +
                                    " até " +
                                    end.getHours() +
                                    ":" +
                                    end.getMinutes();

                                toast({
                                    title: "Você possui um ensaio marcado para:",
                                    position: "top-right",
                                    description: message.toString(),
                                    status: "warning",
                                    duration: 999999,
                                    isClosable: true,
                                });
                            });
                        }
                    }
                    setErrors(response.data);
                    loadBlocks()
                }
                setSaving(false)
                onClose()
                onCloseConfirm()
            }).catch(reason => {
                setSaving(false)
                if (reason.response.status != 201) {


                    onCloseConfirm()
                    onOpen()

                    toast({
                        title: "Não foi possivel criar o bloqueio.",
                        position: "top-right",
                        description: 'Verifique todos os campos',
                        status: "error",
                        duration: 9500,
                        isClosable: true,
                    });

                    if (reason.response.data.return) {
                        reason.response.data.return?.map((res: { start: any; end: any }) => {
                            let start = new Date(res?.start);
                            let end = new Date(res?.end);

                            let message =
                                start.toLocaleDateString("pt-BR", {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                }) +
                                " até " +
                                end.getHours() +
                                ":" +
                                end.getMinutes();

                            toast({
                                title: "Você possui um ensaio marcado para:",
                                position: "top-right",
                                description: message.toString(),
                                status: "warning",
                                duration: 999999,
                                isClosable: true,
                            });
                        });
                    }
                }
                setErrors(reason.response.data.errors);

            });
    };

    const handleCheck = (event: any) => {
        settIsChecked(event.target.checked);
    };
    const handleTimeStart = (event: any) => {
        setTimeStart(event.target.value);
    };
    const handleTimeEnd = (event: any) => {
        setTimeEnd(event.target.value);
    };
    const handleRepeat = (event: any) => {
        setRepeat(event.target.value);
    };
    const handleText = (event: any) => {
        setText(event.target.value);
    };
    const handleDateStar = (event: any) => {
        setDateStart(event);
    };
    const handleDateEnd = (event: any) => {
        setDateEnd(event);
    };

    return (

        <Flex
        >

            <Button onClick={onOpen}>Criar bloqueio</Button>

            <Modal isOpen={isOpen} size="md" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize="lg" fontFamily="base">
                        Detalhes do bloqueio
                    </ModalHeader>
                    <ModalCloseButton />


                    <ModalBody>


                        {saving ?
                            <Flex w={'full'} justifyContent={'center'} my={'30px'} mb={'50px'}>
                                <Spinner size={'xl'}/>
                            </Flex>
                            :
                            <Flex direction={'column'} gap={3}>

                                <Flex direction={'column'} gap={1}>
                                    <Heading variant="bodyTextLarge" color="gray.500">
                                        Data inicial
                                    </Heading>
                                    <DatePicker
                                        dateFormat={"dd/MM/yyyy"}
                                        locale={ptBR}
                                        selected={dateStart}
                                        onChange={handleDateStar}
                                        className="border-1 border-high.medium p-2 w-full rounded-md text-gray-500"
                                    />
                                    {errors?.dateStart &&
                                        <Text fontSize={"sd+"} color={"red.600"}>
                                            {errors?.dateStart}
                                        </Text>
                                    }
                                </Flex>


                                <Flex direction={'column'} gap={1}>
                                    <Heading variant="bodyTextLarge" color="gray.500">
                                        Data final
                                    </Heading>
                                    <DatePicker
                                        dateFormat={"dd/MM/yyyy"}
                                        locale={ptBR}
                                        selected={dateEnd}
                                        onChange={handleDateEnd}
                                        className="border-1 border-high.medium w-full p-2 rounded-md text-gray-500"
                                    />
                                    {errors?.dateEnd && (
                                        <Text fontSize={"sd+"} color={"red.600"}>
                                            {errors?.dateEnd}
                                        </Text>
                                    )}
                                </Flex>

                                {errors?.date && (
                                    <Text fontSize={"sd+"} color={"red.600"}>
                                        {errors?.date}
                                    </Text>
                                )}


                                <div className="grid gap-3">
                                    {/* Hora inicial */}
                                    {!isChecked && (
                                        <div>
                                            <Heading variant="bodyTextLarge" color="gray.500">
                                                Hora inicial
                                            </Heading>
                                            <input
                                                type="time"
                                                id="timeStart"
                                                name="timeStart"
                                                min="07:00"
                                                max="19:00"
                                                onChange={handleTimeStart}
                                                step="1800"
                                                value={timeStart}
                                                className="border-1 w-full border-high.medium p-2 rounded-md w-56  text-gray-500"
                                            ></input>
                                            {errors?.timeStart && (
                                                <Text fontSize={"sd+"} color={"red.600"}>
                                                    {errors?.timeStart}
                                                </Text>
                                            )}
                                        </div>
                                    )}

                                    <div className="grid gap-1">
                                        {/* Hora final */}
                                        {!isChecked && (
                                            <div>
                                                <Heading variant="bodyTextLarge" color="gray.500">
                                                    Hora final
                                                </Heading>
                                                <input
                                                    type="time"
                                                    id="timeEnd"
                                                    name="timeEnd"
                                                    min="07:00"
                                                    max="19:00"
                                                    onChange={handleTimeEnd}
                                                    step="1800"
                                                    value={timeEnd}
                                                    className="border-1 w-full border-high.medium p-2 rounded-md w-56 text-gray-500"
                                                ></input>

                                                {errors?.timeEnd && (
                                                    <Text fontSize={"sd+"} color={"red.600"}>
                                                        {errors?.timeEnd}
                                                    </Text>
                                                )}
                                            </div>
                                        )}

                                        {/* Checkbox */}
                                        <Checkbox
                                            colorScheme="whatsapp"
                                            iconSize=""
                                            isChecked={isChecked}
                                            onChange={handleCheck}
                                        >
                                            <Heading variant="bodyTextMedium" color={'gray.500'}>Dia todo</Heading>
                                        </Checkbox>
                                    </div>
                                </div>

                                <div>
                                    <Heading variant="bodyTextLarge" color="gray.500">
                                        Frequência do bloqueio
                                    </Heading>
                                    <Select value={repeat} onChange={handleRepeat} color={'gray.500'}>
                                        {blockFrequency.map((block) => (
                                            <option value={block} key={block}>
                                                {getFrequencyBlockDescription(block)}
                                            </option>
                                        ))}
                                    </Select>
                                </div>

                                <div>
                                    <Heading variant="bodyTextLarge" color="gray.500">
                                        Motivo do bloqueio
                                    </Heading>
                                    <Textarea
                                        required
                                        value={text}
                                        onChange={handleText}
                                        size="xs"
                                        placeholder="Nos diga com suas palavras o ocorrido."
                                    />
                                    {errors?.reason && (
                                        <Text fontSize={"sd+"} color={"red.600"}>
                                            {errors?.reason}
                                        </Text>
                                    )}
                                </div>

                            </Flex>

                        }
                    </ModalBody>

                    {
                        !saving &&
                        <ModalFooter>
                            <Button variant="tertiary" onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button
                                variant="primary"
                                mr={3}
                                rightIcon={<ChevronRightIcon w={6} h={6} />}
                                onClick={handleConfirmModal}
                            >
                                Criar o bloqueio
                            </Button>
                        </ModalFooter>
                    }

                </ModalContent>
            </Modal>


            <Modal isOpen={isOpenConfirm} size="md" onClose={onCloseConfirm}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize="lg" fontFamily="base">
                        Confirmar Bloqueio
                    </ModalHeader>
                    <ModalCloseButton />


                    <ModalBody>


                        {saving ?
                            <Flex w={'full'} justifyContent={'center'} my={'30px'} mb={'50px'}>
                                <Spinner size={'xl'}/>
                            </Flex>
                            :
                            <Flex direction={'column'} gap={4}>
                                {
                                    Number(repeat) == 0 ?
                                        <>
                                            <Flex flexDirection={'column'} >
                                                <Text><strong>Data inicial:</strong> {formatDateText(dateStart)}  {timeStart}</Text>
                                                <Text><strong>Data final: </strong>{formatDateText(dateEnd)}   {timeEnd}</Text>
                                                <Text><strong>Motivo: </strong> {text}</Text>
                                            </Flex>
                                        </>: <></>
                                }
                                {
                                    Number(repeat) == 1 ?
                                        <>
                                            <Flex flexDirection={'column'} >
                                                <Text><strong>Data inicial:</strong> {formatDateText(dateStart)}</Text>
                                                <Text><strong>Data final: </strong>{formatDateText(dateEnd)} </Text>
                                                <Text>Das <strong>{timeStart}</strong> às <strong>{timeEnd}</strong> horas</Text>
                                                <Text><strong>Nos dias:</strong> {
                                                    dias[new Date(dateStart).getDay() % 7]}</Text>
                                                <Text><strong>Motivo: </strong> {text}</Text>
                                            </Flex>
                                        </>: <></>
                                }

                            </Flex>

                        }
                    </ModalBody>

                    {
                        !saving &&
                        <ModalFooter>
                            <Button variant="tertiary" onClick={onCloseConfirm}>
                                Cancelar
                            </Button>
                            <Button
                                variant="primary"
                                mr={3}
                                rightIcon={<ChevronRightIcon w={6} h={6} />}
                                onClick={handleConfirmModel}
                            >
                                Confirmar
                            </Button>
                        </ModalFooter>
                    }

                </ModalContent>
            </Modal>
        </Flex>

    );
};
export default CalendarBlockCreateModal;
