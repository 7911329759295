import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Flex,
    Heading,
    Input,
    InputGroup, Spacer, Spinner,
    Stack, Text,
    useToast
} from "@chakra-ui/react";
import * as React from 'react';
import {FC, useEffect, useState} from "react";
import SessionCard from "../../components/sessionCard";
import axios from "axios";
import api from "../../services/api";
import Filters from "../../components/filters";
import PiperzStudioIcon from "../../styles/icons/PiperzStudioIcon";
import RedirectIcon from "../../styles/icons/RedirectIcon";
import {BsWhatsapp} from "react-icons/bs";
import {getSessionStatusColor, getSessionStatusDescription} from "../../enum/session/SessionStatus";
import {format} from "date-fns";
import {phoneToWhatsApp} from "../../util/phone-number";
import {Type} from "../../enum/log/type";


interface SessionListTodayCardProps {
    session: {id:string; status:string;
        scheduled_for: string; client:{name:string, logo:string}; address_numero:string; address_rua:string; address_bairro:string; address_cidade: string; address_estado: string;
        items:{id: string; title: string}[]; scheduling_responsible_info: string; created_at: string; scheduling_responsible_info_extra: string; briefing_pre: string; scheduling_responsible_info_phone:string; key_pickup_address:string;
        user:{name:string}, address_link:string, short_address:string, key_pickup_address_link: string, SessionCheckStartProvider: boolean, SessionCheckEndProvider: boolean, confirmation_message: string,
        session_vertical_data_unit: string, session_vertical_data_complement: string, session_vertical_data_area: string, session_vertical_data_reference: string, session_vertical_data_keys: string,
    }
    reminder?: boolean
    bg?: string
}


const SessionListTodayCard: FC<SessionListTodayCardProps> = ({session, reminder= false, bg='#ffff'}) => {


    const whatsAppNotification = phoneToWhatsApp(
        session.scheduling_responsible_info_phone,
        `${
            session?.confirmation_message
        }`
    );

    const saveLog = (value: any) => {
        api.post(`/provider/sessions/log/${session.id}/${value}`)
    }

    return (
        <>
            <Flex as={'a'} href={`/ensaios/${session.id}`} bg={bg} shadow={'0px  0px 7px #97A4B6'} borderRadius={'2xl'} flexDirection={'column'} p={'5'}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Avatar name={session.client.name} src={session.client.logo}/>
                    <Text bg={getSessionStatusColor(Number(session.status))} color={'white'} p={'1'} px={'6'} borderRadius={'2xl'}>{getSessionStatusDescription(Number(session.status))}</Text>
                </Flex>
                <Flex flexDirection={'column'} gap={'0.5'} mt={'3'}>
                    <Text fontWeight={'500'} fontSize={'16px'}>{format(new Date(session.scheduled_for), "HH:mm")} &bull; {new Date(session.scheduled_for).toLocaleDateString(
                            "pt-BR",
                            {
                                day: "2-digit",
                                month: "2-digit",
                            }
                        )}
                        {/*{new Date(session.scheduled_for).toLocaleDateString(*/}
                        {/*    "pt-BR",*/}
                        {/*    {*/}

                        {/*    }*/}
                        {/*)}*/}
                    </Text>
                    <Text fontWeight={'500'} fontSize={'14px'} color={'gray.600'}>ID {session.id} &bull; Ref Int. {session.session_vertical_data_reference}</Text>
                    <Text fontWeight={'400'} fontSize={'14px'} color={'gray.600'}>{session.address_bairro}, {session.address_cidade}</Text>
                </Flex>
                <Flex mt={3}>
                    <Flex gap={'2'}>

                        {session.items.map(value =>
                            <>

                                <Flex p={'2'} bg={'#E3FFED'} borderRadius={'2xl'}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7 10.7906C7.86437 10.7906 8.60002 10.4921 9.20698 9.89518C9.81393 9.29822 10.1174 8.57468 10.1174 7.72455C10.1174 6.87442 9.81393 6.15088 9.20698 5.55392C8.60002 4.95697 7.86437 4.65849 7 4.65849C6.13563 4.65849 5.39997 4.95697 4.79302 5.55392C4.18607 6.15088 3.8826 6.87442 3.8826 7.72455C3.8826 8.57468 4.18607 9.29822 4.79302 9.89518C5.39997 10.4921 6.13563 10.7906 7 10.7906ZM1.33199 13.1598C0.959788 13.1598 0.644738 13.033 0.386843 12.7794C0.128948 12.5257 0 12.2159 0 11.8498V3.59931C0 3.23324 0.128948 2.92338 0.386843 2.66973C0.644738 2.41608 0.959788 2.28926 1.33199 2.28926H3.58219L4.94535 0.839844H9.05465L10.4178 2.28926H12.668C13.0402 2.28926 13.3553 2.41608 13.6132 2.66973C13.8711 2.92338 14 3.23324 14 3.59931V11.8498C14 12.2159 13.8711 12.5257 13.6132 12.7794C13.3553 13.033 13.0402 13.1598 12.668 13.1598H1.33199Z"
                                            fill="#00D99D"/>
                                        <path
                                            d="M8.96 7.83984C8.96 8.92232 8.08248 9.79984 7 9.79984C5.91752 9.79984 5.04 8.92232 5.04 7.83984C5.04 6.75737 5.91752 5.87984 7 5.87984C8.08248 5.87984 8.96 6.75737 8.96 7.83984Z"
                                            fill="#00D99D"/>
                                    </svg>
                                </Flex>
                                <Text my={'auto'}>{value.title}</Text>
                            </>
                        )}
                    </Flex>
                </Flex>
                {
                    reminder &&
                    <Button
                        onClick={()=>saveLog(Type.PROVIDER_MESSAGE_CONFIMATION)}
                        mt={3}
                        colorScheme="whatsapp"
                        rightIcon={<BsWhatsapp />}
                        as={"a"}
                        href={whatsAppNotification}
                        target={"_blank"}
                    >
                        <Text>Lembrar anfitrião</Text>
                    </Button>
                }
            </Flex>
        </>
    );
};

export default SessionListTodayCard;
