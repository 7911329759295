import { Icon } from "@chakra-ui/icons";

interface NewLogoTextProps {
    fill?: string
}

const NotesIcon: React.FC<NewLogoTextProps> = ({fill= '#4D5562'}) => {
  return (
      <svg width="18" height="18" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.92316 16.9167C1.50222 16.9167 1.14591 16.7709 0.854248 16.4792C0.562581 16.1876 0.416748 15.8313 0.416748 15.4103V4.25653C0.416748 3.83559 0.562581 3.47928 0.854248 3.18762C1.14591 2.89595 1.50222 2.75012 1.92316 2.75012H3.07702V0.987305H4.35904V2.75012H10.6731V0.987305H11.9231V2.75012H13.077C13.4979 2.75012 13.8542 2.89595 14.1459 3.18762C14.4375 3.47928 14.5834 3.83559 14.5834 4.25653V9.12831H13.3334V7.58987H1.66673V15.4103C1.66673 15.4744 1.69344 15.5332 1.74685 15.5866C1.80028 15.6401 1.85905 15.6668 1.92316 15.6668H7.62027V16.9167H1.92316ZM1.66673 6.33989H13.3334V4.25653C13.3334 4.19242 13.3067 4.13365 13.2533 4.08022C13.1998 4.02681 13.1411 4.0001 13.077 4.0001H1.92316C1.85905 4.0001 1.80028 4.02681 1.74685 4.08022C1.69344 4.13365 1.66673 4.19242 1.66673 4.25653V6.33989Z" fill={fill}/>
          <path d="M12.9167 18.1667C11.8761 18.1667 10.991 17.8018 10.2613 17.0721C9.53159 16.3424 9.16675 15.4573 9.16675 14.4167C9.16675 13.3761 9.53159 12.491 10.2613 11.7613C10.991 11.0316 11.8761 10.6667 12.9167 10.6667C13.9573 10.6667 14.8425 11.0316 15.5721 11.7613C16.3018 12.491 16.6667 13.3761 16.6667 14.4167C16.6667 15.4573 16.3018 16.3424 15.5721 17.0721C14.8425 17.8018 13.9573 18.1667 12.9167 18.1667ZM14.2917 16.3109L14.8109 15.7917L13.2853 14.2661V11.9648H12.5481V14.5674L14.2917 16.3109Z" fill={fill}/>
      </svg>

  );
};

export default NotesIcon;
