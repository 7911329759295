import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Flex, Grid,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList
} from "@chakra-ui/react";
import { FC, useState } from "react";
import {Link, NavLink} from "react-router-dom";
import NewLogoText from "../styles/icons/NewLogoText";
import Cookie from "js-cookie";
import {useAuth} from "../contexts/auth/AuthContext";
import NotificationIcon from "../styles/icons/NotificationIcon";
import TodayIcon from "../styles/icons/TodayIcon";
import NotesIcon from "../styles/icons/NotesIcon";
import TomorrowIcon from "../styles/icons/TomorrowIcon";
import {bg} from "date-fns/locale";

interface NavBarProps {}

const MenuSessions: FC<NavBarProps> = () => {

    const auth = useAuth()

    const logOut = () => {
        auth.setUser(null)
        Cookie.remove('authentication')
    }

    const [display, setDisplay] = useState("none");

    const changeDisplay = () => {
        if (display === "none") {
            setDisplay("flex");
        } else if (display === "flex") {
            setDisplay("none");
        }
    };

    return (

            <Grid mt={3} templateColumns='repeat(3, 1fr)' gap={2} >
                <NavLink to={'/'} >
                    {({ isActive, isPending }) => (
                        <Button bg={isActive ? '#DEEAFC' : '#EDF2F7'} gap={'2'} p={0} color={isActive?'#0C6FF6':'gray.600'} w={'100%'}>
                            <TodayIcon fill={isActive?'#0C6FF6':'#4D5562'}/> Hoje
                        </Button>
                    )}
                </NavLink>

                <NavLink to={'/ensaios/lembretes'} >
                    {({ isActive, isPending }) => (
                        <Button bg={isActive ? '#DEEAFC' : '#EDF2F7'}  p={0} gap={'2'} color={isActive?'#0C6FF6':'gray.600'} w={'100%'}>
                            <NotesIcon fill={isActive?'#0C6FF6':'#4D5562'}/> Lembretes
                        </Button>
                    )}
                </NavLink>

                <NavLink to={'/ensaios/amanha'} >
                    {({ isActive, isPending }) => (
                        <Button bg={isActive ? '#DEEAFC' : '#EDF2F7'} p={0} gap={'2'} color={isActive?'#0C6FF6':'gray.600'} w={'100%'}>
                            <TomorrowIcon fill={isActive?'#0C6FF6':'#4D5562'}/> Próximos
                        </Button>
                    )}
                </NavLink>


            </Grid>
    );
};

export default MenuSessions;
