import {
    Box,
    Button,
    Checkbox,
    Flex,
    Heading,
    Input,
    InputGroup,
    Stack,
    useToast
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { useAuth } from "../../contexts/auth/AuthContext";
import Cookie from 'js-cookie';
import api from "../../services/api";
import {redirect, useNavigate} from "react-router-dom";
interface LoginProps {}

const Login: FC<LoginProps> = () => {
    const auth = useAuth();

    const [state, setState] = useState({
        email: "",
        password: "",
    });
    const handleChange =
        (key: keyof typeof state) =>
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                setState((state) => ({ ...state, [key]: value }));
            };

    const toast = useToast();
    const navigate = useNavigate()

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        api.post('/provider/auth/login',state).then(value => {
                if (value.status == 200){
                    Cookie.set('authentication', value.data.plainTextToken,{
                        expires: 3,
                        secure: true,
                        path: '/'
                    })

                    api.interceptors.request.use(async config => {
                        config.headers.Authorization = `Bearer ${Cookie.get('authentication')}`;
                        return config;
                    });

                    api.get(`/provider/auth/user`)
                        .then(response => {
                            if (response.status == 200) {
                                auth.setUser(response.data)
                            } else {
                                auth.setUser(null)
                                Cookie.remove('authentication')
                            }
                        })
                        .catch(() => {
                            auth.setUser(null)
                            Cookie.remove('authentication')
                        })
                }
            }
        )


    };

    return (
        <Box
            className="absolute top-0 inset-x-0 h-screen w-full
                bg-white grid grid-cols-1 md:grid-cols-2 md:px-60 px-6 items-center justify-center"
        >
            <img
                src={require("../../images/loginCamera.png")}
                alt=""
                className="w-96 md:mt-0 mt-16 m-auto md:m-0"
            />
            <Flex
                className="flex flex-col gap-3 items-center justify-center"
                as={"form"}
                onSubmit={handleSubmit}
            >
                <Stack spacing={4}>
                    <Heading variant="bodyTextMedium" color="low.pure">
                        Fotógrafo, acesse com seu email!
                    </Heading>

                    <InputGroup>
                        <Input
                            type="text"
                            placeholder="Email"
                            autoComplete="email"
                            onChange={handleChange("email")}
                            value={state.email}
                            required
                        />
                    </InputGroup>

                    <InputGroup>
                        <Input
                            placeholder="Senha"
                            autoComplete="current-password"
                            type="password"
                            onChange={handleChange("password")}
                            value={state.password}
                            required
                        />
                    </InputGroup>
                    <Checkbox colorScheme="whatsapp" defaultChecked size="sm">
                        Lembrar de mim
                    </Checkbox>
                    <Button variant="primary" type={"submit"}>
                        Entrar
                    </Button>
                </Stack>
            </Flex>
        </Box>
    );
};

export default Login;
