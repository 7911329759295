export enum BlockStatus {
  ACTIVE = 0,
  CANCELED = 1,
  PENDING = 2
}

export function getStatuslockColor(status: BlockStatus) {
  switch (status) {
    case BlockStatus.CANCELED:
      return '#dc3545'
    case BlockStatus.PENDING:
      return '#0d6efd'
    default:
      return '#198754'
  }
}

export function getBlockStatusDescription(status: BlockStatus) {
  switch (status) {
    case BlockStatus.ACTIVE:
      return 'Ativa'
    case BlockStatus.CANCELED:
      return 'Cancelada'
    case BlockStatus.PENDING:
      return 'Pendente'
  }
}
