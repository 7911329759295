import {FC, useState} from "react";
import * as React from "react";
import api from "../../services/api";
import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, Flex, Text} from "@chakra-ui/react";
import SessionCard from "../../components/sessionCard";
import {Link} from "react-router-dom";
import {DeleteIcon} from "@chakra-ui/icons";
import {BlockStatus, getBlockStatusDescription} from "../../enum/provider/calendar/BlockStatus";
import CalendarBlockDeleteModal from "./CalerdarBlockDeleteModal";

interface CalendarBlockCardProps {
    block:{
        id:string;
        created_at:string;
        deleted_at:string;
        ends_at:string;
        frequency:string;
        reason:string;
        starts_at:string;
        status:string;
    };
    loadBlocks: ()=>void
}

const CalendarBlockCard: FC<CalendarBlockCardProps> = ({block,loadBlocks}) => {

    return (

        <Card p={'25px'} gap={'4'} className={'card-font'} shadow={'0px  0px 15px #97A4B6'}>

            <Flex direction={'column'} gap={2}>


                <Text opacity={'0.7'} mb={4} fontSize={'lg'}>Bloqueio</Text>

                <Flex gap={2}>
                    <Text opacity={'0.8'}>Data de início: </Text>
                    <Text opacity={'0.5'}>{new Date(block.starts_at).toLocaleDateString(
                        "pt-BR",
                        {
                            day: "2-digit",
                            month: "2-digit",
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                        }
                    )}</Text>
                </Flex>

                <Flex gap={2}>
                    <Text opacity={'0.8'}>Data de término:: </Text>
                    <Text opacity={'0.5'}>{new Date(block.ends_at).toLocaleDateString(
                        "pt-BR",
                        {
                            day: "2-digit",
                            month: "2-digit",
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                        }
                    )}</Text>
                </Flex>
                <Flex gap={2}>
                    <Text opacity={'0.8'}>Motivo: </Text>
                    <Text opacity={'0.5'}>{block.reason}</Text>
                </Flex>
                <Flex gap={2}>
                    <Text opacity={'0.8'}>Status: </Text>
                    <Text opacity={'1'}>{getBlockStatusDescription(Number(block.status))}</Text>
                </Flex>
                <Flex gap={2}>
                    <Text opacity={'0.8'}>Criado em {new Date(block.created_at).toLocaleDateString(
                        "pt-BR",
                        {
                            day: "2-digit",
                            month: "2-digit",
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                        }
                    )}</Text>
                </Flex>
                {block.deleted_at ?
                    <Flex gap={2}>
                        <Text opacity={'0.8'}>Cancelado em {new Date(block.deleted_at).toLocaleDateString(
                            "pt-BR",
                            {
                                day: "2-digit",
                                month: "2-digit",
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            }
                        )}</Text>
                    </Flex>
                    :
                   <CalendarBlockDeleteModal selectBlock={block} loadBlocks={loadBlocks}/>
                }
            </Flex>
        </Card>

    );
};
export default CalendarBlockCard;
