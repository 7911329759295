import {Avatar, Box, Button, Flex, Heading, Spacer, useToast} from "@chakra-ui/react";
import tocanvas from "html2canvas";
import QRCode from "qrcode";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/auth/AuthContext";
import Logo from "../../styles/icons/LogoPiperz";
import Footer from "../../components/Footer";
import * as React from "react";

interface IdentificationProps {}

const Identification: React.FC<IdentificationProps> = () => {
  const toast = useToast();
  const user = useAuth();
  const toShare = useRef<HTMLDivElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);

  const [data, setData] = useState("");
  const url = `https://provider.piperz.io/providerCheck/${user.user?.id}`;

  useEffect(() => {
    QRCode.toCanvas(canvas.current, url, (err) => {
      console.log(err);
    });
    //console.log(toShare.current);
  });

  /* 
  const onShare = async () => {
    if (toShare.current === null) {
      return;
    }

    toJpeg(toShare.current, { quality: 0.95 }).then(function (dataURL: any) {
      setData(dataURL);
    });

    const blob = new Blob([data]);

    const image = new File([blob as BlobPart], "FTPiperz.jpeg", {
      type: blob.type,
    });

    if (navigator.canShare && navigator.canShare({ files: [image] })) {
      try {
        await navigator.canShare({
          files: [image],
          title: "Autorização fotógrafo Piperz",
        });
      } catch (error) {
        
        console.log("Navigator.canShare error: ", error);
      }
    } else {
      toast({
        title: "Erro. Tente usar outro navegador",
        position: "top-right",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      console.log("Navigator.canShare Não suportado/encontrado");
    }
  };
 */

  const onShare = async () => {
    if (toShare.current === null) {
      return;
    }

    if (!navigator.canShare) {
      return console.log("Sharing not supported");
    }

    const el = await tocanvas(toShare.current);

    el.toBlob(async (blob) => {
      const files = [
        new File([blob as BlobPart], "FTPiperz.png", { type: blob?.type }),
      ];
      const shareData = {
        title: "Autorização fotógrafo Piperz",
        files,
      };

      if (navigator.canShare(shareData)) {
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.error(err);
        }
      } else {
        console.log("Sharing not supported", shareData);
      }
    });
  };

  return (
    <Flex direction="column" gap="10">


      <Flex
          flexDirection={["column", "column", "row", "row"]}
          gap={["24px"]}
          zIndex={1}
          maxWidth={'3xl'}
          mx={'auto'}
          m={4}
      >
        <div className={`w-full  md:col-span-2`}>
          <Heading size="medium" color={ "low.pure"}>
            {/* Todos os ensaios */}
            Crachá Virtual
          </Heading>
          <Heading
              variant="bodyTextSmall"
              color={ "low.medium"}
              maxW='40rem'
          >
            Essa é sua identificação como nosso fotógrafo parceiro. Você pode compartilhar sempre
            que suas informações pessoais forem solicitadas. Escolha a melhor forma de
            compartilhamento e facilite o acesso aos imóveis.
          </Heading>
        </div>

          <Spacer />
          <div className="flex items-end justify-center">
            {/* <Filters /> */}

            <Button variant="primary" onClick={onShare}>
              Compartilhar
            </Button>
          </div>
      </Flex>

      <Flex
        bgGradient="linear(to-b, secondary.medium, high.light)"
        py="48px"
        px="16px"
        alignItems="center"
        justifyContent="center"
        maxW="420px"
        margin="auto"
        ref={toShare}
        mx={4}
        mb={'12'}
        borderRadius={'xl'}
      >
        <Flex
          gap="42"
          direction="column"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
        >
          <Logo w="50" />

          <Box textAlign="center">
            <Heading size="medium" color="white">
              {user.user?.name}
            </Heading>
            <Heading variant="bodyTextSmall" color="white">
              Fotógrafo autorizado Piperz
            </Heading>
          </Box>

          <Avatar size="2xl" boxShadow="xl" name={user.user?.name} />

          <Flex
            direction="column"
            gap="2"
            alignItems="center"
            justifyContent="center"
          >
            {user.user?.cpf && (
              <Flex gap="2">
                <Heading
                  variant="bodyTextMedium"
                  fontWeight="600"
                  color="secondary.pure"
                >
                  CPF:
                </Heading>
                <Heading fontWeight="600" variant="bodyTextMedium">
                  {user.user?.cpf}
                </Heading>
              </Flex>
            )}
            {/*{user.user?.provider?.rg && (*/}
            {/*  <Flex gap="2">*/}
            {/*    <Heading*/}
            {/*      variant="bodyTextMedium"*/}
            {/*      fontWeight="600"*/}
            {/*      color="secondary.pure"*/}
            {/*    >*/}
            {/*      RG:*/}
            {/*    </Heading>*/}
            {/*    <Heading fontWeight="600" variant="bodyTextMedium">*/}
            {/*      {user.user?.provider.rg}*/}
            {/*    </Heading>*/}
            {/*  </Flex>*/}
            {/*)}*/}
          </Flex>
          <Box boxShadow="xs">
            <canvas ref={canvas} id="canvas"></canvas>
          </Box>

          <Heading variant="bodyTextMedium">
            Escaneie o QR Code e acesse nossa plataforma para confirmação do
            Fotográfo.
          </Heading>
        </Flex>
      </Flex>

      <Footer/>
    </Flex>
  );
};

export default Identification;
function toast(arg0: {
  title: string;
  position: string;
  status: string;
  duration: number;
  isClosable: boolean;
}) {
  throw new Error("Function not implemented.");
}
function html2canvas(current: HTMLDivElement) {
  throw new Error("Function not implemented.");
}
