export const InputStyles = {
    baseStyles: {},
  
    variants: {
      outline: {
        field: {
          borderRadius: "6px",
          _focus: {
            borderColor: "primary.pure",
          },
        },
        addon: {
          color: "low.pure",
          fontWeight: 500,
          fontSize: "sm",
          lineHeight: "lg",
          height: "46px",
          padding: "10px 16px",
          display: "flex",
          alignItems: "flex-start",
          borderRadius: "md",
        },
      },
    },
    sizes: {
      lg: {
        field: {
          height: "46px",
          border: "1px solid",
          borderColor: "high.medium",
        },
      },
    },
  
    defaultProps: {},
  };
  