import { extendTheme } from "@chakra-ui/react";
import { ButtonStyles as Button } from "./components/buttonStyles";
import { HeadingStyles as Heading } from "./components/headingStyles";
import { IconButtonStyles as IconButton } from "./components/iconButtonStyles";
import { InputStyles as Input } from "./components/inputStyles";
import { NavBarStyles as Menu } from "./components/navBarStyles";
import { TabStyles as Tab } from "./components/tabStyles";
import { TextareaStyles as Textarea } from "./components/textareaStyles";
import { TextStyles as Text } from "./components/textStyles";

export const ptheme = extendTheme({
  colors: {
    primary: {
      pure: "#0CF67E",
      light: "#00D99D",
      medium: "#92F2B3",
      dark: "#E3FFED",
    },
    secondary: {
      pure: "#0C6FF6",
      light: "#408AEA",
      medium: "#7AB4FF",
      dark: "#CCE2FF",
    },
    tertiary: {
      pure: "#FED530",
    },
    highlight: {
      pure: "#F673AD",
      light: "#FDD9E9",
      medium: "#900945",
      dark: "#430420",
    },
    success: {
      pure: "#0087FF",
      light: "#C2EDFF",
      medium: "#0573B0",
      dark: "#024054",
    },
    atention: {
      pure: "#FDC800",
      light: "#FFEFB2",
      medium: "#A98D21",
      dark: "#463907",
    },
    error: {
      pure: "#FF0000",
      light: "#FFC2C2",
      medium: "#CC0000",
      dark: "#7A0000",
    },
    low: {
      pure: "#2D2D2D",
      light: "#A3A3A3",
      medium: "#666666",
      dark: "#292929",
    },
    high: {
      pure: "#FCFCFC",
      light: "#F5F5F5",
      medium: "#E0E0E0",
      dark: "#CCCCCC",
    },
  },
  fonts: {
    highlight: "",
    base: "Outfit",
  },
  fontSizes: {
    xxxs: "10px",
    xxs: "12px",
    xs: "14px",
    sm: "16px",
    md: "18px",
    lg: "22px",
    xl: "26px",
    xxl: "32px",
    xxxl: "48px",
    display: "64px",
    giant: "96px",
  },
  lineHeights: {
    xs: "100%",
    sm: "120%",
    md: "140%",
    lg: "160%",
  },
  radii: {
    none: "0px",
    xms: "2px",
    sm: "4px",
    md: "6px",
    lg: "8px",
    xlg: "12px",
    xxlg: "16px",
    pill: "500px",
    circular: "50%",
  },

  /** COMPONENTS */
  components: {
    Button,
    Heading,
    Text,
    IconButton,
    Textarea,
    Menu,
    Input,
    Tab,
  },
});
