import {
    Box,
    Button,
    Checkbox, Divider,
    Flex,
    Heading,
    Input,
    InputGroup, Spacer, Spinner,
    Stack, Text,
    useToast
} from "@chakra-ui/react";
import * as React from 'react';
import {FC, useEffect, useState} from "react";
import SessionCard from "../../components/sessionCard";
import axios from "axios";
import api from "../../services/api";
import Filters from "../../components/filters";
import {format} from "date-fns";
import {getSessionStatusColor, getSessionStatusDescription} from "../../enum/session/SessionStatus";
import PiperzStudioIcon from "../../styles/icons/PiperzStudioIcon";
import RedirectIcon from "../../styles/icons/RedirectIcon";
import SessionListTodayCard from "./SessionListTodayCard";
import Footer from "../../components/Footer";
import MenuSessions from "../../components/MenuSessions";


interface SessionListTomorrowProps {}

const SessionListNext: FC<SessionListTomorrowProps> = () => {

    const [sessions, setSessions] = useState<{id:string; status:string;
        scheduled_for: string; client:{name:string, logo:string}; address_numero:string; address_rua:string; address_bairro:string; address_cidade: string; address_estado: string;
        items:{id: string; title: string}[]; scheduling_responsible_info: string; created_at: string; scheduling_responsible_info_extra: string; briefing_pre: string; scheduling_responsible_info_phone:string; key_pickup_address:string;
        user:{name:string}, address_link:string, short_address:string, key_pickup_address_link: string, SessionCheckStartProvider: boolean, SessionCheckEndProvider: boolean, confirmation_message: string,
        session_vertical_data_unit: string, session_vertical_data_complement: string, session_vertical_data_area: string, session_vertical_data_reference: string, session_vertical_data_keys: string,
    }[]>([]);

    const [filter, setFilter] = useState<object>({});
    const [load, setLoad] = useState(false);
    const [paging, setPaging] = useState<Http.Pagination>({
        page: 1,
        length: 21,
    });




    useEffect(() => {
        setLoad(true)
        api.post(`/provider/sessions`,{filter, next: true})
            .then(response => {
                if (response.status ==200) {
                    setSessions(response.data)
                }
                setLoad(false)
            })
    }, [filter])

    return (
        <>
            <Box
                justifyContent={'center'}
                mb={'80px'}
                m={'4'}
            >
                <MenuSessions/>
                {/*<Flex shadow={'0px  0px 3px #97A4B6'} px={'10'} p={'4'} borderRadius={'xl'} mt={'5'}>*/}
                {/*    <Text textAlign={'center'} opacity={'0.6'} fontWeight={'500'} w={'100%'}>*/}
                {/*        As produções podem ser agendadas até às 18h.*/}
                {/*    </Text>*/}
                {/*</Flex>*/}
                <Flex mt={6} flexDirection={'column'} gap={'6'} mb={24}>
                    {sessions.map(value =>
                        <SessionListTodayCard session={value} reminder={false} bg={'#DEEAFC'}/>
                    )}
                </Flex>
                <Footer/>
            </Box>
        </>
    );
};

export default SessionListNext;
