import {FC, useEffect, useState} from "react";
import * as React from "react";
import api from "../../services/api";
import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex} from "@chakra-ui/react";
import SessionCard from "../../components/sessionCard";
import CalendarBlockCard from "./CalendarBlockCard";
import CalendarBlockCreateModal from "./CalerdarBlockCreateModal";
import Footer from "../../components/Footer";
import {useAuth} from "../../contexts/auth/AuthContext";

const CalendarView: FC<{}> = () => {

    const [blocks, setBlocks] = useState<
        {
            id:string;
            created_at:string;
            deleted_at:string;
            ends_at:string;
            frequency:string;
            reason:string;
            starts_at:string;
            status:string;

        }[]>([]);

    const loadBlocks = () => {
        api.post('provider/block').then(value =>
            setBlocks(value.data.data)
        )
    }
    useEffect(() => {
        loadBlocks()
    },[])

    const user = useAuth();
    return (

        <Flex
            display={'flex'}
            justifyContent={'center'}
            mb={'80px'}
            mt={'30px'}
            p={'4'}
        >
            <iframe style={{width:'100%'}} src={`https://calendar.google.com/calendar/embed?src=${user.user?.google_calendar_id}&ctz=America%2FSao_Paulo`}  height="600" frameBorder="0" scrolling="no"></iframe>
        <Footer/>
        </Flex>

    );
};
export default CalendarView;
