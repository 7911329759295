interface NewLogoTextProps {
    fill?: string
}

const TomorrowIcon: React.FC<NewLogoTextProps> = ({fill= '#4D5562'}) => {
  return (
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_2430_815" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
              <rect x="0.666504" width="20" height="20" fill={fill}/>
          </mask>
          <g mask="url(#mask0_2430_815)">
              <path d="M13.1906 17.9167V16.6668H16.2435C16.3076 16.6668 16.3663 16.6401 16.4198 16.5866C16.4732 16.5332 16.4999 16.4744 16.4999 16.4103V8.58987H4.83323V11.8751H3.58325V5.25653C3.58325 4.83559 3.72909 4.47928 4.02075 4.18762C4.31242 3.89595 4.66872 3.75012 5.08967 3.75012H6.24352V1.9873H7.52554V3.75012H13.8396V1.9873H15.0896V3.75012H16.2435C16.6644 3.75012 17.0207 3.89595 17.3124 4.18762C17.604 4.47928 17.7499 4.83559 17.7499 5.25653V16.4103C17.7499 16.8313 17.604 17.1876 17.3124 17.4792C17.0207 17.7709 16.6644 17.9167 16.2435 17.9167H13.1906ZM7.33323 19.7036L6.46306 18.8334L8.80923 16.4584H1.70825V15.2085H8.80923L6.46306 12.8334L7.33323 11.9633L11.2034 15.8334L7.33323 19.7036ZM4.83323 7.33989H16.4999V5.25653C16.4999 5.19242 16.4732 5.13365 16.4198 5.08022C16.3663 5.02681 16.3076 5.0001 16.2435 5.0001H5.08967C5.02556 5.0001 4.96679 5.02681 4.91336 5.08022C4.85994 5.13365 4.83323 5.19242 4.83323 5.25653V7.33989Z" fill={fill}/>
          </g>
      </svg>

  );
};

export default TomorrowIcon;
