import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Flex,
    Heading,
    Input,
    InputGroup, Spacer, Spinner,
    Stack, Text,
    useToast
} from "@chakra-ui/react";
import * as React from 'react';
import {FC, useEffect, useState} from "react";
import SessionCard from "../../components/sessionCard";
import axios from "axios";
import api from "../../services/api";
import Filters from "../../components/filters";
import PiperzStudioIcon from "../../styles/icons/PiperzStudioIcon";
import RedirectIcon from "../../styles/icons/RedirectIcon";
import {BsWhatsapp} from "react-icons/bs";
import SessionListTodayCard from "./SessionListTodayCard";
import Footer from "../../components/Footer";
import MenuSessions from "../../components/MenuSessions";


interface LoginProps {}

const SessionListToday: FC<LoginProps> = () => {

    const [sessions, setSessions] = useState<{id:string; status:string;
        scheduled_for: string; client:{name:string, logo:string}; address_numero:string; address_rua:string; address_bairro:string; address_cidade: string; address_estado: string;
        items:{id: string; title: string}[]; scheduling_responsible_info: string; created_at: string; scheduling_responsible_info_extra: string; briefing_pre: string; scheduling_responsible_info_phone:string; key_pickup_address:string;
        user:{name:string}, address_link:string, short_address:string, key_pickup_address_link: string, SessionCheckStartProvider: boolean, SessionCheckEndProvider: boolean, confirmation_message: string,
        session_vertical_data_unit: string, session_vertical_data_complement: string, session_vertical_data_area: string, session_vertical_data_reference: string, session_vertical_data_keys: string,
    }[]>([]);

    const [filter, setFilter] = useState<object>({});
    const [load, setLoad] = useState(false);
    const [paging, setPaging] = useState<Http.Pagination>({
        page: 1,
        length: 21,
    });




    useEffect(() => {
        setLoad(true)
        api.post(`/provider/sessions`,{filter, today: true})
            .then(response => {
                if (response.status ==200) {
                    setSessions(response.data)
                }
                setLoad(false)
            })
    }, [filter])

    return (
        <>

            <Box
                justifyContent={'center'}
                m={'4'}
            >
                <MenuSessions/>
                <Flex mt={4} display={''} bg={'#00D99D'} borderRadius={'2xl'} flexDirection={'column'} >
                    <Flex justifyContent={'space-between'} px={'3'}>
                        <PiperzStudioIcon />
                        <Button bg={'white'} borderRadius={'3xl'} p={'0'} shadow={'lg'} mt={3}>
                            <RedirectIcon />
                        </Button>
                    </Flex>
                    <Text color={'white'} ml={'28'}  mr={'1'} fontSize={'sm'} textAlign={'right'}>
                        Utilize a versão desktop para a entrega das produções.
                    </Text>
                </Flex>

                <Spacer mt={'9'}/>

                <Flex flexDirection={'column'} gap={'6'} mb={24}>
                    {sessions.map(value =>
                        <SessionListTodayCard session={value}/>
                    )}
                </Flex>
            </Box >
            <Footer />
        </>
    );
};

export default SessionListToday;
