import React, { useEffect, useState } from 'react'
import api from "../services/api";

type SetMeta<T> = React.Dispatch<React.SetStateAction<T | undefined>>


export function useSession(id: string | undefined) {
  const [session, setSession] = useState<{ data?: Resource.SessionResource , status: number } | null>()
    useEffect(() => {
    const url =  `/provider/sessions/${id}`
    api.get(url)
      .then(response => {
        if (response.status == 200){
          setSession({data: response.data , status: response.status})
        }else {
            setSession({ status: 500})
        }
      }).catch(reason =>
        setSession({ status: 500})
    )
  }, [id])
  return session
}
