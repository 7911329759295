import { ArrowForwardIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Avatar, Badge,
  Box,
  Button, Card,
  Center, Divider,
  Flex,
  Heading, Icon,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Radio, RadioGroup, SimpleGrid,
  Spacer, Stack,
  Tag,
  Text,
  Textarea,
  UnorderedList,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { FC, useState } from "react";
import {BsWhatsapp} from "react-icons/bs";



interface SessionCardProps {
  client: {
    id:string;
    briefing_photo:string;
    briefing_video: string;
    created_at:string;
    deleted_at: string|null;
    name: string;
    items:{
      price:string;
      title:string;
      id:string
    }[];
    regions:{
      name:string;
      id:string
    }[];
  }

}

const ClientCard: FC<SessionCardProps> = ({client}: SessionCardProps) => {


  return (
    <>
      <Card p={'15px'} gap={'4'} className={'card-font'} shadow={'0px  0px 15px #97A4B6'}>

        <Flex gap={'2'} fontFamily={'Lexend'}>
          <Avatar name={client.name} />
          <Text my={'auto'} fontWeight={'500'} fontSize={'16px'}>{client.name}</Text>
        </Flex>
        <Flex gap={'2'}>
          <Text my={'auto'}>Parceria:</Text>
          {client.deleted_at ?
              <Flex bg={'#fa0c0c'} p={'2'} px={'4'} w={"min"} borderRadius={'3xl'}>
                <Text color={'white'} fontSize={'14px'}>DESATIVADA</Text>
              </Flex>
              :
              <Flex bg={'#00D99D'} p={'2'} px={'4'} w={"min"} borderRadius={'3xl'}>
                <Text color={'white'} fontSize={'14px'}>ATIVA</Text>
              </Flex>
          }

        </Flex>


        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                  <Text>Áreas de atuação</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} >
              <Flex gap={'2'} direction={'column'}>
                {client.regions.map(value =>
                    <Box className={'grid-cols-3'} >
                      <Flex bg={'#DEEAFC'} p={'6px'} px={'10px'} borderRadius={'xl'}>
                        <Text color={'#0C6FF6'}>{value.name}</Text>
                      </Flex>
                    </Box>
                )}

              </Flex>

            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Flex direction={'column'} gap={'3'}>
          <Text>Serviço</Text>
          <Flex gap={'2'}>
            {client.items.map(value =>
                <>
                  <Flex p={'2'} bg={'#E3FFED'} borderRadius={'2xl'}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M7 10.7906C7.86437 10.7906 8.60002 10.4921 9.20698 9.89518C9.81393 9.29822 10.1174 8.57468 10.1174 7.72455C10.1174 6.87442 9.81393 6.15088 9.20698 5.55392C8.60002 4.95697 7.86437 4.65849 7 4.65849C6.13563 4.65849 5.39997 4.95697 4.79302 5.55392C4.18607 6.15088 3.8826 6.87442 3.8826 7.72455C3.8826 8.57468 4.18607 9.29822 4.79302 9.89518C5.39997 10.4921 6.13563 10.7906 7 10.7906ZM1.33199 13.1598C0.959788 13.1598 0.644738 13.033 0.386843 12.7794C0.128948 12.5257 0 12.2159 0 11.8498V3.59931C0 3.23324 0.128948 2.92338 0.386843 2.66973C0.644738 2.41608 0.959788 2.28926 1.33199 2.28926H3.58219L4.94535 0.839844H9.05465L10.4178 2.28926H12.668C13.0402 2.28926 13.3553 2.41608 13.6132 2.66973C13.8711 2.92338 14 3.23324 14 3.59931V11.8498C14 12.2159 13.8711 12.5257 13.6132 12.7794C13.3553 13.033 13.0402 13.1598 12.668 13.1598H1.33199Z"
                          fill="#00D99D"/>
                      <path
                          d="M8.96 7.83984C8.96 8.92232 8.08248 9.79984 7 9.79984C5.91752 9.79984 5.04 8.92232 5.04 7.83984C5.04 6.75737 5.91752 5.87984 7 5.87984C8.08248 5.87984 8.96 6.75737 8.96 7.83984Z"
                          fill="#00D99D"/>
                    </svg>
                  </Flex>
                  <Text my={'auto'}>{value.title}</Text>
                </>
            )}
          </Flex>
        </Flex>

        <Accordion allowMultiple>


          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize={'16px'}>Valores (por ensaio)</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} >
              <Flex gap={'3'} direction={'column'}>

                {client.items.map(value =>
                  <Flex bg={'#EAEEF4'} p={'4px'} px={'15px'} borderRadius={'lg'} gap={'3'}>

                    <Flex p={'2'} bg={'#E3FFED'} borderRadius={'2xl'}>
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 10.7906C7.86437 10.7906 8.60002 10.4921 9.20698 9.89518C9.81393 9.29822 10.1174 8.57468 10.1174 7.72455C10.1174 6.87442 9.81393 6.15088 9.20698 5.55392C8.60002 4.95697 7.86437 4.65849 7 4.65849C6.13563 4.65849 5.39997 4.95697 4.79302 5.55392C4.18607 6.15088 3.8826 6.87442 3.8826 7.72455C3.8826 8.57468 4.18607 9.29822 4.79302 9.89518C5.39997 10.4921 6.13563 10.7906 7 10.7906ZM1.33199 13.1598C0.959788 13.1598 0.644738 13.033 0.386843 12.7794C0.128948 12.5257 0 12.2159 0 11.8498V3.59931C0 3.23324 0.128948 2.92338 0.386843 2.66973C0.644738 2.41608 0.959788 2.28926 1.33199 2.28926H3.58219L4.94535 0.839844H9.05465L10.4178 2.28926H12.668C13.0402 2.28926 13.3553 2.41608 13.6132 2.66973C13.8711 2.92338 14 3.23324 14 3.59931V11.8498C14 12.2159 13.8711 12.5257 13.6132 12.7794C13.3553 13.033 13.0402 13.1598 12.668 13.1598H1.33199Z"
                            fill="#00D99D"/>
                        <path
                            d="M8.96 7.83984C8.96 8.92232 8.08248 9.79984 7 9.79984C5.91752 9.79984 5.04 8.92232 5.04 7.83984C5.04 6.75737 5.91752 5.87984 7 5.87984C8.08248 5.87984 8.96 6.75737 8.96 7.83984Z"
                            fill="#00D99D"/>
                      </svg>
                    </Flex>

                    <Text my={'auto'}>{value.title}</Text>
                    <Spacer/>
                    <Text opacity={'0.4'}>{Number(value.price).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Text>
                  </Flex>
                )}

                  {/*<Flex bg={'#EAEEF4'} p={'4px'} px={'15px'} borderRadius={'lg'} gap={'3'}>*/}

                  {/*  <Flex p={'2'} bg={'#E3FFED'} borderRadius={'2xl'}>*/}
                  {/*    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                  {/*      <path d="M7 10.7906C7.86437 10.7906 8.60002 10.4921 9.20698 9.89518C9.81393 9.29822 10.1174 8.57468 10.1174 7.72455C10.1174 6.87442 9.81393 6.15088 9.20698 5.55392C8.60002 4.95697 7.86437 4.65849 7 4.65849C6.13563 4.65849 5.39997 4.95697 4.79302 5.55392C4.18607 6.15088 3.8826 6.87442 3.8826 7.72455C3.8826 8.57468 4.18607 9.29822 4.79302 9.89518C5.39997 10.4921 6.13563 10.7906 7 10.7906ZM1.33199 13.1598C0.959788 13.1598 0.644738 13.033 0.386843 12.7794C0.128948 12.5257 0 12.2159 0 11.8498V3.59931C0 3.23324 0.128948 2.92338 0.386843 2.66973C0.644738 2.41608 0.959788 2.28926 1.33199 2.28926H3.58219L4.94535 0.839844H9.05465L10.4178 2.28926H12.668C13.0402 2.28926 13.3553 2.41608 13.6132 2.66973C13.8711 2.92338 14 3.23324 14 3.59931V11.8498C14 12.2159 13.8711 12.5257 13.6132 12.7794C13.3553 13.033 13.0402 13.1598 12.668 13.1598H1.33199Z" fill="#00D99D"/>*/}
                  {/*      <path d="M8.96 7.83984C8.96 8.92232 8.08248 9.79984 7 9.79984C5.91752 9.79984 5.04 8.92232 5.04 7.83984C5.04 6.75737 5.91752 5.87984 7 5.87984C8.08248 5.87984 8.96 6.75737 8.96 7.83984Z" fill="#00D99D"/>*/}
                  {/*    </svg>*/}
                  {/*  </Flex>*/}

                  {/*  <Text my={'auto'}>Foto</Text>*/}
                  {/*  <Spacer/>*/}
                  {/*  <Text opacity={'0.4'}>R$ 00,00</Text>*/}
                  {/*</Flex>*/}

              </Flex>

            </AccordionPanel>
          </AccordionItem>


          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize={'16px'}>Briefing padrão fotografia</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {client.briefing_photo}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize={'16px'}>Briefing padrão video</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} >
              {client.briefing_video}
            </AccordionPanel>
          </AccordionItem>

        </Accordion>

        {/*<Flex direction={'column'} gap={'2'}>*/}
        {/*  <Text>Contato:</Text>*/}

        {/*  <Text>Operacional:</Text>*/}
        {/*  <Flex direction={'column'} opacity={'0.5'}>*/}
        {/*    <Text>Nome Sobrenome</Text>*/}
        {/*    <Text>mail@mail.com</Text>*/}
        {/*    <Flex><Text>(11) 99000-0000</Text><Icon as={BsWhatsapp} /></Flex>*/}
        {/*  </Flex>*/}

        {/*  <Text>Financeiro:</Text>*/}
        {/*  <Flex direction={'column'} opacity={'0.5'}>*/}
        {/*    <Text>Nome Sobrenome</Text>*/}
        {/*    <Text>mail@mail.com</Text>*/}
        {/*    <Flex><Text>(11) 99000-0000</Text><Icon as={BsWhatsapp} /></Flex>*/}
        {/*  </Flex>*/}
        {/*</Flex>*/}
      </Card>
    </>
  );
};

export default ClientCard;

