import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import Cookie from "js-cookie";

type useContextProps = {
    children: ReactNode
}
type authContextType = {
    user: {
        id: string
        created_at?: string
        updated_at?: string
        deleted_at?: string
        cpf?: string
        email: string
        name: string
        phone: string
        profile: number
        type: number
        google_calendar_id?: string
        permissions: Record<string, boolean>
    } | null | undefined
    setUser: (newState: any | undefined) => void
}
const initialValue = {
    user: undefined,
    setUser: ()=>{},
}

export const AuthContext = createContext<authContextType>(initialValue)

export function useAuth() {
    const auth = useContext(AuthContext)!
    return auth
}

export const AuthContextProvider = ({children}: useContextProps)  => {
    const [user, setUser] = useState<any>(undefined);

    useEffect( () => {


        api.interceptors.request.use(async config => {
            config.headers.Authorization = `Bearer ${Cookie.get('authentication')}`;
            return config;
        });

        api.get(`/provider/auth/user`)
            .then(response => {
                if (response.status == 200) {
                    setUser(response.data)
                } else {
                    setUser(null)
                    Cookie.remove('authentication')
                }
            })
            .catch(() => {
                setUser(null)
                Cookie.remove('authentication')
            })

    }, [])

    return (
        <AuthContext.Provider value={{user, setUser}} >
            {children}
        </AuthContext.Provider>
    )
}
