export const TextareaStyles = {
  baseStyles: {},

  variants: {
    outline: {
      borderRadius: "md",
      _focus: {
        borderColor: "primary.pure",
      },
    },
  },
  sizes: {
    lg: {
      field: {
        height: "46px",
        border: "1px solid",
        borderColor: "high.medium",
      },
    },
  },

  defaultProps: {},
};